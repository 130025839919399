@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }
}

/* Ensure your container spans the full height and prevents overflow */
.app-container {
  min-height: 100vh;
  overflow: auto;
}

/* Each section should take full width and ensure proper padding and margin */
section {
  width: 100%;
  padding: 20px 0; /* Adjust padding as needed */
  margin: 20px 0; /* Adjust margin as needed */
}
